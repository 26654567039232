import React from "react";
import { Link } from "gatsby";
import { Table } from "antd";

import Api from "../components/api";
import Console from "../components/console";
import Seo from "../components/seo";

const columns = [
    {
        title: 'N° Compte',
        dataIndex: 'id',
        key: 'id',
        render: (text, record) => <Link to={`/account?id=${record.id}`}>{text}</Link>
    },
    {
        title: 'Type de compte',
        dataIndex: 'type',
        key: 'type',
        render: (text, record) => <Link to={`/account?id=${record.id}`}>{text}</Link>
    },
    {
        title: 'Solde',
        dataIndex: 'amount',
        key: 'amount',
        render: (text, record) => <Link to={`/account?id=${record.id}`}>{text.toLocaleString("fr", {minimumFractionDigits: 2})} €</Link>
    }
];

class Accounts extends React.Component {

    state = {
        data: null,
        user: null
    }

    componentDidMount() {
        this.load();
    }

    load = () => {
        this.setState({data: null, user: null});
        Api("user_details", {})
                .then(user => {
                    this.setState({user});
                });
        Api("accounts", {})
                .then(data => {
                    this.setState({data: data.accounts});
                });
    }

    render() {
        const {data, user} = this.state;
        return <Console menu="accounts" help={["user_details", "accounts", "transaction_validation_notification"]} onChangeUser={this.load}>
            <Seo title="Liste des comptes" />
            <h1>Bonjour { user ? user.firstname + " " + user.lastname : "..."}</h1>
            <Table dataSource={data} loading={!data} columns={columns} />
        </Console>;
    }
}



export default Accounts;
